import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Asset from "./Asset";
import { setloading } from "../../redux/sceneSlice";
import { useHandlePVsSwitch } from "../CustomHooks";
import { reduxLocalStorage } from "../utility/storage";
function PVPanels(props) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const project_id = reduxLocalStorage.getItem("project_id");
  const dispatch = useDispatch();
  const pvPanel = useSelector((state) => state.sceneVar.pvPanels);
  const pvFilter = useSelector((state) => state.sceneVisibility.pvFilter);

  useHandlePVsSwitch()
  return (
    (
      <group visible={pvPanel?.length}>
        {Object.keys(pvFilter)?.map(scenario => {
          return (
            <Asset
              url={`${apiUrl}project/glb/?project_id=${project_id}&type=${scenario}`}
              visible={pvFilter?.[scenario]}
              color="#1c4083"
              onFulfilled={() => dispatch(setloading({ index: 9, value: false }))}
              onLoading={() => dispatch(setloading({ index: 9, value: true }))}
            />
          )
        })}
      </group>
    )
  );
}
export default React.memo(PVPanels);
