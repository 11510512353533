import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  Label,
} from "recharts";
import { setEnergyBoxView, showEnergyData } from "../../redux/roomData";
import { useDispatch } from "react-redux";
import { updateFilter } from "../../redux/sceneGlobal";
import { setLastChosenScenario } from "../../redux/roomData";
import {
  generateColor,
  roomEnergyDataInfoBoxScale,
  formatNumber,
  daylightFactorScale,
} from "../utils";
import { setDaylightBoxView } from "../../redux/daylightFactor";
import { useColorBar } from "../CustomHooks";
import ConfigurationLayout from "./ConfigurationLayout";
import { reduxLocalStorage } from "../utility/storage";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          width: "150px",
          height: "80px",
          padding: "8px",
          backgroundColor: "white",
          border: "1px solid #aaa",
        }}
      >
        <p className="">{label}</p>
        <p className="">{`${payload[0].value?.toFixed(2)}`}</p>
      </div>
    );
  }
};

const DaylightInfoBoxView = () => {
  const roomData = useSelector((state) => state.daylightFactor);

  // const minMaxDict = useSelector((state) => state.roomData.rangeValues);
  const daylightBoxView = roomData.daylightBoxView;
  var unit = "%";

  const scenarioNameListString = reduxLocalStorage.getItem("daylight_scenario_list");
  var scenarioNamesList = scenarioNameListString.split(",");

  var dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  const refOne = useRef(null);
  const handleClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      dispatch(setDaylightBoxView(false));
    }
  };

  const roomIsShowing = roomData.roomFirstIsShowing;
  const scenarioList = roomData.daylightScenarioList;
  const coordinates = roomData.roomPosition;
  const currentFloorNameValue = useSelector(
    (state) => state.floorVisibility.lastSelectedFloor
  );
  const subcategory = roomData.value.parentName;
  const { max, min } = useColorBar("Daylight Factor", subcategory, 0, 100);
  var output = [];
  if (scenarioList) {
    var maxScenario = Math.max(...scenarioList);
    output = scenarioList.slice(0, 6).map((scenario, index) => ({
      name: scenarioNamesList[index],
      scenarioNumber: scenario,
      fill: generateColor(scenario, min, max, daylightFactorScale),
    }));
  }

  return (
    roomIsShowing && daylightBoxView && (
      <ConfigurationLayout
        title={roomData.roomName}
        style={{ width: '500px', height: '600px' }} onClickOutside={() => dispatch(setDaylightBoxView(false))}>
        <p style={{ fontWeight: "bold", fontSize: 14 }}>
          Scenario Distribution
        </p>

        <BarChart
          width={460}
          height={200}
          data={output}
          margin={{
            top: 5,
            right: 30,
            left: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />

          <YAxis
            domain={[
              // minMaxDict[currentValue]?.min,
              // Math.max(0, minMaxDict[currentValue]?.min),
              0,

              // minMaxDict[currentValue].max,
              maxScenario > 0 ? maxScenario ?? 100 : 100,
            ]}
            tickFormatter={(x) => x.toFixed(2)}
          >
            <Label
              angle={-90}
              style={{
                textAnchor: "middle",
                fontWeight: "bold",
                color: "blue",
              }}
              dy="100"
              dx="100"
              position="left"
            >
              {unit}
            </Label>
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          {/* <Legend /> */}
          <Bar
            dataKey="scenarioNumber"
            fill="#8884d8"
          // onClick={(e) => {
          //   dispatch(
          //     showEnergyData([
          //       `${currentFloorNameValue}${roomData.currentValue}${roomData.lastChosenScenario}`,
          //     ])
          //   );
          //   // dispatch(setLastChosenScenario([e.name]));

          //   dispatch(
          //     showEnergyData([
          //       `${currentFloorNameValue}${roomData.currentValue}${e.name}`,
          //     ])
          //   );
          //   dispatch(
          //     updateFilter([
          //       `Energy Data >> ${roomData.currentValue} >> ${e.name}`,
          //     ])
          //   );
          //   dispatch(setLastChosenScenario([e.name]));
          // }}
          />
        </BarChart>
        <div>room area : {formatNumber(roomData.roomArea)}</div>
        {/* </div> */}
      </ConfigurationLayout>
    )
  );
};

export default DaylightInfoBoxView;
