import React, { useCallback, useLayoutEffect, useRef } from "react";
import * as THREE from "three";
// import * as THREE from "https://threejs.org/build/three.module.js";
import { useSelector, useDispatch } from "react-redux";
import {
  updateWindowPosition,
  setTrue,
  toggleBoxForClickOutsideOfBox,
} from "../../redux/windowConfiguration";
import axios from "axios";
import { useState, useEffect } from "react";
import { setloading } from "../../redux/sceneSlice";
import { useHandleWindowsSwitch } from "../CustomHooks";
import { getClickedWindowColor } from "../utils";
import { invalidate } from "@react-three/fiber";
import { arePolygonsSimilar, arrayScale, arraysSubtract } from "../utility/dataManipulation";
import { SimilarPolygonObjectsMesh } from "./InstancedMesh";
import { FlatMeshGeometry } from "./Geometry";
import { reduxLocalStorage } from "../utility/storage";


const WindowMeshBasicMaterial = ({ color }) => {
  return (
    <meshBasicMaterial
      attach="material"
      side={THREE.DoubleSide}
      color={'#fff'}
    />
  );
};


const isWindowinFloor = (window, data, selectedFloor, index2) => {
  for (const floorIndex in data) {
    const floor = data[floorIndex];
    if (selectedFloor[floorIndex])
      for (const windowIndex in floor) {
        const floorWindow = floor[windowIndex];
        if (window.id === floorWindow.id) {
          return true;
        }
      }
  }
  return false;
};



function SimilarWindowsOld({ windows, visible, data }) {
  const dispatch = useDispatch();
  const selectedFloor = useSelector(
    (state) => state.floorVisibility.selectedFloors
  );
  const instancedMeshRef = useRef();

  var clickedWindowId = useSelector(
    (state) => state.shadingConfigurationWindow.id
  );

  const getDefaultWindowColor = () => {
    return "#07cdf5";
  };

  const getWindowColor = (windowId) => {
    if (windowId === clickedWindowId) return getClickedWindowColor()
    return getDefaultWindowColor()
  }

  let baseWindow = windows[0];
  const count = windows?.length;

  const paint = () => {
    let temp = new THREE.Object3D();
    if (windows?.length > 0) {
      let i = 0;
      windows.forEach((window) => {
        const windowInFloor = isWindowinFloor(window, data, selectedFloor);
        const scaleFactor = [1, 1, 1];
        const arr1 = new Float32Array(JSON.parse(window.polygon));
        const arr2 = new Float32Array(JSON.parse(baseWindow.polygon));
        const position = arraysSubtract(arr1, arrayScale(arr2, scaleFactor));
        temp.scale.set(scaleFactor[0], scaleFactor[1], scaleFactor[2]);
        if (!windowInFloor) {
          temp.scale.set(0, 0, 0);
        }

        temp.position.set(position[0], position[1], position[2]);
        temp.updateMatrix();
        instancedMeshRef.current.setMatrixAt(i, temp.matrix);
        const color = new THREE.Color(getWindowColor(window.id))
        instancedMeshRef.current?.setColorAt(i, color);
        i += 1;
      });
      instancedMeshRef.current.instanceMatrix.needsUpdate = true;
      instancedMeshRef.current.instanceColor.needsUpdate = true
    }
  }

  useLayoutEffect(() => { paint() }, [windows, selectedFloor, clickedWindowId]);

  return (
    <group
      rotation-x={Math.PI * -0.5}
      dispose={null}
      visible={visible}
      onClick={(config) => {
        if (!visible) return;
        const window = windows[config.instanceId];
        dispatch(toggleBoxForClickOutsideOfBox());
        dispatch(setTrue());
        dispatch(
          updateWindowPosition([
            null,
            null,
            window.name,
            window.id,
          ])
        );
        config.stopPropagation();
      }}
    >
      <instancedMesh
        ref={instancedMeshRef} args={[null, null, count]}>
        <bufferGeometry>
          <bufferAttribute
            attach="attributes-position"
            array={new Float32Array(JSON.parse(baseWindow.polygon))}
            count={new Float32Array(JSON.parse(baseWindow.polygon)).length / 3}
            itemSize={3}
          />
        </bufferGeometry>
        <WindowMeshBasicMaterial />
      </instancedMesh>
    </group>
  );
}


function SimilarWindows({ windows, visible, data }) {
  const dispatch = useDispatch();
  const selectedFloor = useSelector(
    (state) => state.floorVisibility.selectedFloors
  );
  var clickedWindowId = useSelector(
    (state) => state.shadingConfigurationWindow.id
  );

  const getDefaultWindowColor = () => {
    return "#07cdf5";
  };

  const getWindowColor = (windowId) => {
    if (windowId === clickedWindowId) return getClickedWindowColor()
    return getDefaultWindowColor()
  }

  const setColor = (object) => {
    return new THREE.Color(getWindowColor(object?.id))
  }
  const onClick = (config) => {
    if (!visible) return;
    const window = windows[config.instanceId];
    dispatch(toggleBoxForClickOutsideOfBox());
    dispatch(setTrue());
    dispatch(
      updateWindowPosition([
        null,
        null,
        window.name,
        window.id,
      ])
    );
    config.stopPropagation();
  }
  const isValid = (object) => {
    return isWindowinFloor(object, data, selectedFloor);
  }
  const objects = windows?.map(window => {
    try {
      const parsed = JSON.parse(window?.polygon)
      return { ...window, polygon: parsed }
    }
    catch {
      return window
    }
  })

  return (
    <SimilarPolygonObjectsMesh
      objects={objects}
      setColor={setColor}
      isValid={isValid}
      updateDependencyArray={[windows, selectedFloor, clickedWindowId]}
      groupProps={{ visible, onClick }}
    />
  )

}

const Window = () => {
  const project_id = reduxLocalStorage.getItem("project_id");
  const token = localStorage.getItem("token");
  const [resultArray, setResultArray] = useState([]);

  const dispatch = useDispatch();
  let allURLs = [];
  var floors = useSelector((state) => state.sceneVar.floorsList);

  floors.forEach((eachFloor, i) => {
    allURLs.push(
      `${process.env.REACT_APP_API_URL}api/window_polygon/?project_id=${project_id}&floor=${eachFloor}`
    );
  });
  var targetBuildingScene = useSelector(
    (state) => state.sceneVisibility.value[4]
  );
  var responses = [];
  useEffect(() => {
    dispatch(setloading({ index: 4, value: true }));

    axios
      .all(
        allURLs.map((endpoint) =>
          axios.get(endpoint, {
            headers: {
              // "Content-Type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${token}`,
            },
          })
        )
      )
      .then((data) => {
        dispatch(setloading({ index: 4, value: false }));
        data.forEach((val) => {
          responses.push(val.data);
        });
        setResultArray(responses);
        // setNodes(true);

        // dispatch(updateApiData([responses]));
      })
      .catch(() => {
        dispatch(setloading({ index: 4, value: false }));
      });
  }, []);

  const [groupedWindows, setGroupedWindows] = useState([]);
  useEffect(() => {
    let jj = 0;
    const groupedWindowsTemp = [];
    resultArray.forEach((floorWindows, index) => {
      floorWindows.forEach((window) => {
        jj++;
        const windowPolygon = new Float32Array(JSON.parse(window.polygon));
        let isSimilar = false;
        for (const group of groupedWindowsTemp) {
          const reperesentor = new Float32Array(JSON.parse(group[0].polygon));
          if (arePolygonsSimilar(reperesentor, windowPolygon)) {
            isSimilar = true;
            group.push(window);
            break;
          }
        }
        if (!isSimilar) groupedWindowsTemp.push([window]);
      });
    });
    setGroupedWindows(groupedWindowsTemp);
  }, [resultArray]);

  useHandleWindowsSwitch();
  const randomNumberForForceRecreate = Math.random()
  var clickedWindowId = useSelector(
    (state) => state.shadingConfigurationWindow.id
  );
  if (!resultArray || !resultArray?.length > 0) return;
  return (
    <>
      {groupedWindows.map((group, index) => {
        return (
          <SimilarWindows
            key={index + clickedWindowId + randomNumberForForceRecreate}
            windows={group}
            visible={targetBuildingScene}
            data={resultArray}
          />
        );
      })}
    </>
  );
};



export default React.memo(Window);
