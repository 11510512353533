import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  Label,
} from "recharts";
import { setEnergyBoxView, showEnergyData } from "../../redux/roomData";
import { useDispatch } from "react-redux";
import { updateFilter } from "../../redux/sceneGlobal";
import { setLastChosenScenario } from "../../redux/roomData";
import {
  generateColor,
  roomEnergyDataInfoBoxScale,
  formatNumber,
} from "../utils";
import { reduxLocalStorage } from "../utility/storage";

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          width: "150px",
          height: "80px",
          padding: "8px",
          backgroundColor: "white",
          border: "1px solid #aaa",
        }}
      >
        <p className="">{label}</p>
        <p className="">{`${payload[0].value?.toFixed(2)}`}</p>
      </div>
    );
  }
};

const EnergyDataInfoBox = (
) => {
  const minMaxDict = useSelector((state) => state.roomData.rangeValues);
  const currentValue = useSelector((state) => state.roomData.currentValue);
  const energyBoxView = useSelector((state) => state.roomData.energyBoxView);
  if (currentValue) {
    var unit;
    unit = currentValue.split("[");
  }

  const scenarioNameListString = reduxLocalStorage.getItem("scenario_list");
  var scenarioNamesList = scenarioNameListString.split(",");

  var dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  const refOne = useRef(null);
  const handleClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      dispatch(setEnergyBoxView(false));
    }
  };

  const roomIsShowing = useSelector(
    (state) => state.roomData.roomFirstIsShowing
  );
  const roomDataSelector = useSelector((state) => state.roomData);
  const scenarioList = useSelector(
    (state) => state.roomData.roomEnergyScenarioList
  );
  const coordinates = useSelector((state) => state.roomData.roomPosition);
  const currentFloorNameValue = useSelector(
    (state) => state.floorVisibility.lastSelectedFloor
  );

  var output = [];
  if (scenarioList) {
    var maxScenario = Math.max(...scenarioList);
    output = scenarioList.slice(0, 6).map((scenario, index) => ({
      name: scenarioNamesList[index],
      scenarioNumber: scenario,
      fill: generateColor(scenario, 0, maxScenario, roomEnergyDataInfoBoxScale),
    }));
  }

  return (
    <>
      {
        //     ifSolarSensors[3] &&

        roomIsShowing && (
          <div
            ref={refOne}
            style={{
              display: `${energyBoxView ? "block" : "none"}`,
              width: "510px",
              height: "310px",
              borderRadius: "10px",
              background: "#ffffff",
              left: coordinates.pagex,
              top: coordinates.pagey,
              position: "absolute",
              zIndex: 1000,
              padding: "10px",
            }}
          >
            <p style={{ fontWeight: "bold", fontSize: 14 }}>
              Scenario Distribution
            </p>

            <BarChart
              width={460}
              height={200}
              data={output}
              margin={{
                top: 5,
                right: 30,
                left: 30,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />

              <YAxis
                domain={[
                  // minMaxDict[currentValue]?.min,
                  Math.max(0, minMaxDict[currentValue]?.min),
                  // 0,

                  // minMaxDict[currentValue].max,
                  maxScenario > minMaxDict[currentValue]?.min
                    ? maxScenario || minMaxDict[currentValue]?.max
                    : minMaxDict[currentValue]?.max,
                ]}
                tickFormatter={(x) => x.toFixed(2)}
              >
                <Label
                  angle={-90}
                  style={{
                    textAnchor: "middle",
                    fontWeight: "bold",
                    color: "blue",
                  }}
                  dy="100"
                  dx="100"
                  position="left"
                >
                  {"[" + unit[1]}
                </Label>
              </YAxis>
              <Tooltip content={<CustomTooltip />} />
              {/* <Legend /> */}
              <Bar
                dataKey="scenarioNumber"
                fill="#8884d8"
                onClick={(e) => {
                  dispatch(
                    showEnergyData([
                      `${currentFloorNameValue}${roomDataSelector.currentValue}${roomDataSelector.lastChosenScenario}`,
                    ])
                  );
                  // dispatch(setLastChosenScenario([e.name]));

                  dispatch(
                    showEnergyData([
                      `${currentFloorNameValue}${roomDataSelector.currentValue}${e.name}`,
                    ])
                  );
                  dispatch(
                    updateFilter([
                      `Energy Data >> ${roomDataSelector.currentValue} >> ${e.name}`,
                    ])
                  );
                  dispatch(setLastChosenScenario([e.name]));
                }}
              />
            </BarChart>
            <div>room name : {roomDataSelector.roomName}</div>
            {/* <div>room area : {Math.round(roomDataSelector.roomArea)}</div> */}
            <div>room area : {formatNumber(roomDataSelector.roomArea)}</div>
            <div>room function : {roomDataSelector.roomFunction}</div>
          </div>
        )
      }
    </>
  );
};

export default EnergyDataInfoBox;
