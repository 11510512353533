import React from "react";
import axios from "axios";
import SingleFloorRooms from "./SingleFloorRooms";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  roomConfigurationStateChanger,
  setEnergyBoxView,
  updateApiData,
} from "../../redux/roomData";
import { setRange } from "../../redux/roomData";
import { setloading } from "../../redux/sceneSlice";
import { useUniqueRoomsFunctions } from "../CustomHooks";
import { getRoomTypeColor } from "../utils";
import { getOldProjectId } from "../utility/hardconding";
import { reduxLocalStorage } from "../utility/storage";

const Rooms = () => {
  const project_id = reduxLocalStorage.getItem("project_id");
  const oldProjectId = getOldProjectId(project_id)
  const token = localStorage.getItem("token");
  function generateMinMax(minmaxList) {
    const minVal = Math.min(...minmaxList);
    const maxVal = Math.max(...minmaxList);
    return { minVal, maxVal };
  }
  const dispatch = useDispatch();
  const [resultArray, setResultArray] = useState();
  const [nodes, setNodes] = useState(false);
  let params = {
    project: 1,
  };
  let query = Object.keys(params)
    .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
    .join("&");
  let floorsList;
  var energyValueData;
  let scenarioList;

  floorsList = reduxLocalStorage.getItem("floor_list");
  floorsList = floorsList.split(",");

  scenarioList = reduxLocalStorage.getItem("scenario_list");
  scenarioList = scenarioList.split(",");
  energyValueData = reduxLocalStorage.getItem("value_list");
  energyValueData = energyValueData.split(",");
  const roomConfigurationState = useSelector(
    (store) => store.roomData.roomConfigurationState
  );

  let allURLs = [];

  floorsList.forEach((floorVal) => {
    allURLs.push(
      `${process.env.REACT_APP_API_URL}api/roomdata/?project_id=${project_id}&floor=${floorVal}`
    );
  });
  var targetBuildingScene = useSelector(
    (state) => state.sceneVisibility.value[5]
  );
  var responses = [];
  useEffect(() => {
    dispatch(setloading({ index: 5, value: true }));

    axios
      .all(
        allURLs.map((endpoint) =>
          axios.get(endpoint, {
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${token}`,
            },
          })
        )
      )
      .then((data) => {
        dispatch(setloading({ index: 5, value: false }));
        data.forEach((val) => {
          responses.push(val.data);
        });
        setResultArray(responses);
        dispatch(updateApiData([responses]));
        var allList = [];
        for (let g = 0; g < energyValueData.length; g++) {
          var output = [];
          for (let i = 0; i < scenarioList.length; i++) {
            for (let d = 0; d < responses.length; d++) {
              for (let b = 0; b < responses[d].length; b++) {
                try {
                  var values = JSON.parse(responses[d][b].values);
                  output.push(values[0][g][energyValueData[g]][i]);
                } catch { }
              }
            }
          }
          allList.push(output);
        }
        var minMaxList = [];
        for (let o = 0; o < allList.length; o++) {
          minMaxList.push(generateMinMax(allList[o]));
        }
        var minMaxDict = {};
        for (let g = 0; g < energyValueData.length; g++) {
          minMaxDict[energyValueData[g]] = {
            min: minMaxList[g].minVal,
            max: minMaxList[g].maxVal,
          };

          if (
            energyValueData[g] == "Cooling demand per m2 [kWh/m2]" &&
            oldProjectId === 9
          ) {
            minMaxDict[energyValueData[g]].max = 300;
          }
        }
        dispatch(setRange([minMaxDict]));
      })
      .catch(() => {
        dispatch(setloading({ index: 5, value: false }));
      });
  }, []);
  return (
    <>
      {
        resultArray?.map((roomData, index) => {
          if (roomData)
            return (
              <SingleFloorRooms
                data={roomData}
                roomIndex={index}
                stateChanger={(value) => dispatch(setEnergyBoxView(value))}
                roomConfigurationState={roomConfigurationState}
                roomConfigurationStateChanger={(value) =>
                  dispatch(roomConfigurationStateChanger(value))
                }
                visible={targetBuildingScene}
              />
            );
        })}
    </>
  );
};

export default React.memo(Rooms);
