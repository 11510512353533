import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Asset from "./Asset";
import { setloading } from "../../redux/sceneSlice";
import { reduxLocalStorage } from "../utility/storage";
function InternalWalls(props) {
  const project_id = reduxLocalStorage.getItem("project_id");
  const dispatch = useDispatch();
  const selectedFloor = useSelector(
    (state) => state.floorVisibility.selectedFloors
  );
  const innerWallsList = useSelector((state) => state.sceneVar.internalWalls);
  var targetBuildingScene = useSelector(
    (state) => state.sceneVisibility.value[2]
  );
  return (
    innerWallsList && (
      <group name="internal walls" visible={targetBuildingScene}>
        {innerWallsList.map((floor, index) => {
          return (
            <>
              <Asset
                url={`${process.env.REACT_APP_API_URL}project/glb/?project_id=${project_id}&type=${floor}`}
                visible={selectedFloor[index]}
                onFulfilled={
                  index === innerWallsList.length - 1
                    ? () => dispatch(setloading({ index: 2, value: false }))
                    : null
                }
                onLoading={() =>
                  dispatch(setloading({ index: 2, value: true }))
                }
              />
            </>
          );
        })}
      </group>
    )
  );
}

export default React.memo(InternalWalls);
