import { setItem, removeItem } from '../../redux/localStorage';
import { store } from '../../redux/store';

export const reduxLocalStorage = {
    setItem: (key, value) => {

        // Mimic the localStorage behavior
        localStorage.setItem(key, value)
        const valueToSave = localStorage.getItem(key)

        store.dispatch(setItem({ key, value: valueToSave }));
        localStorage.removeItem(key)
    },
    getItem: (key) => {
        const state = store.getState().reduxLocalStorage;
        return state?.[key] || null;
    },
    removeItem: (key) => {
        store.dispatch(removeItem({ key }));
    },
};