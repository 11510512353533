import React from "react";
import chroma from "chroma-js";
import { useEffect, useRef } from "react";
import { ArrowHelper, InstancedMesh, Matrix4, Vector3 } from "three";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useState } from "react";
import {
  setIsLoading,
  updateRangeVirtualWind,
} from "../../redux/windDirection";
import { windDirectionScale, normalize, generateColor } from "../utils";
import * as THREE from "three";
import { useColorBar } from "../CustomHooks";
import { reduxLocalStorage } from "../utility/storage";

function SingleWindDirectionOld({ inputData, visible, maxValue }) {
  return (
    <group rotation-x={Math.PI * -0.5} visible={visible}>
      {inputData.map((wind, index) => (
        <React.Fragment key={index}>
          <primitive
            object={
              new ArrowHelper(
                new Vector3(wind.vx, wind.vy, wind.vz).normalize(),
                new Vector3(wind.x, wind.y, wind.z),
                2,
                generateColor(wind.value, 0, 14, windDirectionScale),
                0.7,
                0.36
              )
            }
          />
        </React.Fragment>
      ))}
    </group>
  );
}

const WindDirectionVisualization = ({ resultArray }) => {
  var windSelector = useSelector((state) => state.windDirection.value);
  var windRanges = useSelector((state) => state.windDirection.rangeList);
  const { max, min } = useColorBar(
    "Wind Sensors",
    "0",
    windRanges[0],
    windRanges[1]
  );
  return (
    <group>
      {resultArray.map((windBatch, index) => {
        return (
          <>
            <SingleWindDirection
              key={windSelector + windBatch}
              inputData={windBatch}
              visible={windSelector.checkStatus[index]}
              min={min}
              max={max}
            />
          </>
        );
      })}
    </group>
  );
}


const WindDirection = () => {
  const dispatch = useDispatch();
  const project_id = reduxLocalStorage.getItem("project_id");
  const token = localStorage.getItem("token");
  var windSelector = useSelector((state) => state.windDirection.value);

  var windDirectionSelector = useSelector(
    (state) => state.sceneVar.windDirectionList
  );
  const [resultArray, setResultArray] = useState();
  const [nodes, setNodes] = useState(false);
  let allURLs = [];
  if (windDirectionSelector) {
    windDirectionSelector.forEach((windDirectionVal) => {
      allURLs.push(
        `${process.env.REACT_APP_API_URL}api/virtualwind/?project_id=${project_id}&direction=${windDirectionVal}`
      );
    });
  }
  var responses = [];
  useEffect(() => {
    if (windDirectionSelector && allURLs.length && !nodes && windSelector.checkStatus.some(e => !!e)) {
      dispatch(setIsLoading(true));
      axios
        .all(
          allURLs.map((endpoint) =>
            axios.get(endpoint, {
              headers: {
                // "Content-Type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${token}`,
              },
            })
          )
        )
        .then((data) => {
          dispatch(setIsLoading(false));
          data.forEach((val) => {
            responses.push(val.data);
          });
          setResultArray(responses);
          setNodes(true);
        })
        .catch(() => {
          dispatch(setIsLoading(false));
        });
    }
  }, [windSelector.checkStatus]);

  if (nodes) {
    var wholeList = [];
    let minVal, maxVal
    for (let j = 0; j < resultArray.length; j++) {
      for (let f = 0; f < resultArray[j].length; f++) {
        const value = resultArray[j][f].value
        wholeList.push(value);
        if ((minVal === undefined && value !== undefined) || value < minVal) minVal = value
        if ((maxVal === undefined && value !== undefined) || value > maxVal) maxVal = value
      }
    }
    dispatch(updateRangeVirtualWind([minVal, maxVal]));
  }
  return (
    <>
      {nodes && (
        <WindDirectionVisualization resultArray={resultArray} />
      )}
    </>
  );
};

function SingleWindDirection({ inputData, visible, min, max }) {
  const coneGeometry = new THREE.ConeGeometry(0.25, 0.7, 4);
  const coneMaterial = new THREE.MeshBasicMaterial();

  var lineMaterial = new THREE.LineBasicMaterial({
    color: 0xffffff,
    vertexColors: true,
    linewidth: 1,
  });
  const points = [];
  const colors = [];
  const instancedMeshRef = useRef();
  if (inputData?.length > 0 && instancedMeshRef.current) {
    inputData.forEach((wind, index) => {
      const scaleFactor = 1.5;
      const normalized = new Vector3(wind.vx, wind.vy, wind.vz).normalize();
      const { vx, vy, vz } = {
        vx: scaleFactor * normalized.x,
        vy: scaleFactor * normalized.y,
        vz: scaleFactor * normalized.z,
      };

      const matrix = new Matrix4();
      matrix.compose(
        new Vector3(wind.x + vx, wind.y + vy, wind.z + vz),
        new THREE.Quaternion().setFromUnitVectors(
          new Vector3(0, 1, 0),
          new Vector3(normalized.x, normalized.y, normalized.z)
        ),
        new Vector3(1, 1, 1)
      );
      points.push(new THREE.Vector3(wind.x, wind.y, wind.z));
      points.push(new THREE.Vector3(wind.x + vx, wind.y + vy, wind.z + vz));

      const color = new THREE.Color(
        generateColor(wind.value, min, max, windDirectionScale)
      );
      colors.push(color.r);
      colors.push(color.g);
      colors.push(color.b);
      colors.push(color.r);
      colors.push(color.g);
      colors.push(color.b);

      instancedMeshRef.current?.setMatrixAt(index, matrix);
      instancedMeshRef.current?.setColorAt(index, color);
    });

    instancedMeshRef.current.count = inputData.length;
  }
  var fcolors = new Float32Array(colors);

  var lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
  lineGeometry.setAttribute("color", new THREE.BufferAttribute(fcolors, 3));
  var lines = new THREE.LineSegments(lineGeometry, lineMaterial);

  const [rerenderFlag, setRerenderFlag] = useState(false)
  useEffect(() => {
    instancedMeshRef.current.dispose();
    setRerenderFlag(!rerenderFlag)
  }, [min, max, inputData, visible]);
  return (
    <>
      <group rotation-x={Math.PI * -0.5} visible={visible}>
        <instancedMesh
          ref={instancedMeshRef}
          args={[null, null, inputData.length]}
          material={coneMaterial}
          geometry={coneGeometry}
        />
      </group>
      <group rotation-x={Math.PI * -0.5} visible={visible}>
        <primitive object={lines} />
      </group>
    </>
  );
}

export default WindDirection;
