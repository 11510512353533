import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Asset from "./Asset";
import { setloading } from "../../redux/sceneSlice";
import { reduxLocalStorage } from "../utility/storage";

function SceneGLTF() {
  const project_id = reduxLocalStorage.getItem("project_id");
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();

  const surroundingVisibility = useSelector(
    (state) => state.sceneVisibility.surroundingValue
  );

  const assetProps = useMemo(
    () => [
      { type: "surr36", visibilityIndex: 0, color: "#cbd1c5" },
      { type: "reduced", visibilityIndex: 2, color: "#0c8505" },
      { type: "terrainreduced", visibilityIndex: 3, color: "#d7faf2" },
    ],
    []
  );

  if (!project_id || !apiUrl) {
    return null;
  }

  return (
    <group name="scene1">
      {assetProps.map((props, index) => (
        <Asset
          key={index}
          url={`${apiUrl}project/glb/?project_id=${project_id}&type=${props.type}`}
          visible={surroundingVisibility[props.visibilityIndex]}
          color={props.color}
          onFulfilled={
            index === assetProps.length - 1
              ? () => dispatch(setloading({ index: 0, value: false }))
              : null
          }
          onLoading={() => dispatch(setloading({ index: 0, value: true }))}
        />
      ))}
    </group>
  );
}
export default React.memo(SceneGLTF);
