import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import * as THREE from "three";
import { useSelector, useDispatch } from "react-redux";
import {
  generateColor,
  generateMinMax,
  generatePolygon,
  pvYieldScale,
  roomEnergyDataScale,
} from "../utils";

import {
  setEnergyBoxView,
  updateRoomEnergyConfigurationPosition,
} from "../../redux/roomData";
import { energyGenerationToggle, setPVDataIsLoading, setPVRange } from "../../redux/pvData";
import axios from "axios";
import { useColorBar } from "../CustomHooks";
import { SimilarPolygonObjectsMesh } from "./InstancedMesh";
import { arePolygonsSimilar, arrayScale, arraysSubtract } from "../utility/dataManipulation";
import { reduxLocalStorage } from "../utility/storage";

const PVMeshBasicMaterial = () => {
  return (
    <meshBasicMaterial
      attach="material"
      side={THREE.DoubleSide}
      color="#fff"
    />
  );
};
const SimilarPVsOld = ({ PVs, visible, currentValue, min, max, selectedScenarios }) => {

  const instancedMeshRef = useRef();
  let basePV = PVs[0];
  const count = PVs?.length;
  useEffect(() => {
    let temp = new THREE.Object3D();
    if (PVs?.length > 0) {
      PVs.forEach((pv, i) => {
        let isValid = true;
        const polygon = pv.polygon;

        const scaleFactor = [1, 1, 1];
        const arr1 = new Float32Array(polygon);
        const arr2 = new Float32Array(basePV.polygon);
        const position = arraysSubtract(arr1, arrayScale(arr2, scaleFactor));
        temp.scale.set(scaleFactor[0], scaleFactor[1], scaleFactor[2]);
        if (!isValid) {
          temp.scale.set(0, 0, 0);
        }
        temp.position.set(position[0], position[1], position[2]);
        temp.updateMatrix();
        instancedMeshRef.current.setMatrixAt(i, temp.matrix);

        const color = new THREE.Color(
          generateColor(
            currentValue === "kWh/m2"
              ? Number(pv.perM2)
              : Number(pv.annual_yield),
            min,
            max,
            pvYieldScale
          )
        );
        instancedMeshRef.current?.setColorAt(i, color);
      });
      instancedMeshRef.current.instanceMatrix.needsUpdate = true;
    }
  }, [min, max, PVs, visible, selectedScenarios]);



  return (
    <group rotation-x={Math.PI * -0.5} dispose={null} visible={visible}>
      <instancedMesh ref={instancedMeshRef} args={[null, null, count]}>
        <bufferGeometry>
          <bufferAttribute
            attach="attributes-position"
            array={new Float32Array(basePV.polygon)}
            count={new Float32Array(basePV.polygon).length / 3}
            itemSize={3}
          />
        </bufferGeometry>
        <PVMeshBasicMaterial />
      </instancedMesh>
    </group>
  );
};

const SimilarPVs = ({ PVs, visible, currentValue, min, max, selectedScenarios }) => {
  const setColor = (object) => {
    return new THREE.Color(
      generateColor(
        currentValue === "kWh/m2"
          ? Number(object.perM2)
          : Number(object.annual_yield),
        min,
        max,
        pvYieldScale
      )
    );
  }
  return (
    <SimilarPolygonObjectsMesh
      objects={PVs}
      setColor={setColor}
      updateDependencyArray={[min, max, PVs, visible, selectedScenarios]}
      groupProps={{ visible }}
    />
  )
};


const PVData = () => {
  const dispatch = useDispatch();
  const [nodes, setNodes] = useState(false);
  const [resultArray, setResultArray] = useState([]);
  const project_id = reduxLocalStorage.getItem("project_id");
  const { haveRange, filter } = useSelector((state) => state.pvData);
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setPVDataIsLoading(true));
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/pv_panel/?project_id=${project_id}`
        );
        if (response.status === 200) {
          setNodes(true)
          const { minVal, maxVal } = generateMinMax(
            response.data,
            "annual_yield"
          );
          const perM2 = response.data.map((e) => {
            const parsedPolygon = JSON.parse(e.pv_panel_polygon);
            const polygon = [
              ...parsedPolygon[0],
              ...parsedPolygon[1],
              ...parsedPolygon[2],
              ...parsedPolygon[2],
              ...parsedPolygon[3],
              ...parsedPolygon[4],
            ];
            return {
              ...e,
              perM2: e.area,
              polygon,
            };
          });
          const { minVal: minValPerM2, maxVal: maxValPerM2 } = generateMinMax(
            perM2,
            "perM2"
          );
          dispatch(
            setPVRange({
              "kWh": [minVal, maxVal],
              "kWh/m2": [minValPerM2, maxValPerM2],
            })
          );
          setResultArray(perM2);
        }
      } finally {
        dispatch(setPVDataIsLoading(false));
      }
    };
    if (!nodes && Object.values(filter)?.some(e => e))
      fetchData();
  }, [filter]);

  const selectedScenarios = Object.entries(filter)
    ?.filter(([_scenario, isShown]) => isShown)
    ?.map(([scenario, _isShown]) => scenario)

  if (!resultArray?.length > 0 || !haveRange)
    return;

  return (
    <>
      {selectedScenarios.map((scenario, index) => {
        return (
          <SingleScenarioPVData
            key={index + scenario}
            resultArray={resultArray?.filter(row => selectedScenarios?.includes(row?.zone))}
            // resultArray={resultArray}
            scenario={scenario}
          />
        );
      })}
    </>
  );
};

const SingleScenarioPVData = ({ resultArray, scenario }) => {
  const { rangeValues, haveRange, filter, unit } = useSelector((state) => state.pvData);
  const selectedScenarios = Object.entries(filter)?.filter(([scenario, isShown]) => isShown)?.map(([scenario, isShown]) => scenario)
  var showData = Object.values(filter)?.some((e) => e);

  const dataMax = unit ? rangeValues?.[unit]?.[1] : null;
  const { max, min } = useColorBar("PV Panel", unit, 0, dataMax);

  const [groupedPVs, setGroupedPVs] = useState([]);
  useLayoutEffect(() => {
    let jj = 0;
    const groupedPVsTemp = [];
    resultArray?.forEach((pv, index) => {
      jj++;
      const PvPolygon = new Float32Array(pv.polygon);
      let isSimilar = false;
      for (const group of groupedPVsTemp) {
        const reperesentor = new Float32Array(group[0].polygon);
        if (arePolygonsSimilar(reperesentor, PvPolygon)) {
          isSimilar = true;
          group.push(pv);
          break;
        }
      }
      if (!isSimilar) groupedPVsTemp.push([pv]);
    });
    if (groupedPVsTemp?.length) setGroupedPVs(groupedPVsTemp);
  }, [resultArray, scenario]);

  return (
    <>
      {groupedPVs.map((group, index) => {
        return (
          <SimilarPVs
            key={index + " " + min + " " + max + scenario + unit}
            PVs={group}
            visible={true}
            min={min}
            max={max}
            currentValue={unit}
          />
        );
      })}
    </>
  );
};



export default PVData;
