import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const reduxLocalStorageSlice = createSlice({
    name: 'reduxLocalStorage',
    initialState,
    reducers: {
        setItem: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
        },
        removeItem: (state, action) => {
            const { key } = action.payload;
            delete state[key];
        },
    },
});

export const { setItem, removeItem } = reduxLocalStorageSlice.actions;
export default reduxLocalStorageSlice.reducer;
