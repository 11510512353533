import React from "react";
import "../NavBar.css";
import { Dropdown, Checkbox } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { useSelector, useDispatch } from "react-redux";
import {
  showEnergyData,
  setModalScenarioIsShowing,
  toggleRoomVisibility,
  setCurrentValue,
  setLastChosenScenario,
  setScenarioDescription,
  hideEnergyData,
  roomEnergyToggle,
  setCurrentValueIndex,
} from "../../redux/roomData";
import { setCurrentFloorName } from "../../redux/floorVisibility";
import { updateFilter } from "../../redux/sceneGlobal";
import { toggleInfoBpx } from "../../redux/infoBoxGeneral";
import { Button } from "@mui/material";
import { deselectAllTarget } from "../../redux/sceneSlice";
import { isOptionSelected } from "../utility/helpers";
import { reduxLocalStorage } from "../utility/storage";

const DropDownEnergyData = (props) => {
  const selectedFloor = useSelector(
    (state) => state.floorVisibility.selectedFloors
  );
  let floorsList;
  floorsList = reduxLocalStorage.getItem("floor_list");
  floorsList = floorsList.split(",");
  const scenarioListString = reduxLocalStorage.getItem("scenario_list");
  var scenarioList = scenarioListString.split(",");

  var scenarioDescriptionList = useSelector(
    (state) => state.roomData.scenarioDescriptionList
  );
  const selector = useSelector((state) => state.roomData.value);

  const dispatch = useDispatch();
  return (
    <Dropdown
      item
      simple
      text={props.data}
      className={`change-width  ${selector.parentName === props.data && "selected-item"
        }`}
    >
      <Dropdown.Menu>
        <Dropdown.Header content="Scenarios" />
        {scenarioList.map((scenarioValue, index) => {
          const isSelected = isOptionSelected({
            selector,
            parentName: props.data,
            childName: scenarioValue,
          });
          return (
            <Dropdown.Item
              className={`drop-down-item ${isSelected && "selected-item"}`}
              onClick={() => {
                dispatch(
                  roomEnergyToggle({
                    parentName: props.data,
                    childName: scenarioValue,
                  })
                );
                dispatch(deselectAllTarget());
                dispatch(setModalScenarioIsShowing());
                dispatch(setLastChosenScenario([scenarioValue]));
                for (let f = 0; f < selectedFloor.length; f++) {
                  if (selectedFloor[f] == true) {
                    dispatch(setCurrentFloorName([floorsList[f]]));
                    // dispatch(hideEnergyData());
                    dispatch(
                      showEnergyData([
                        `${floorsList[f]}${props.data}${scenarioValue}`,
                      ])
                    );

                    dispatch(
                      updateFilter([
                        `${props.parentTitle} >> ${props.data} >> ${scenarioValue}`,
                      ])
                    );
                  }
                }
                dispatch(toggleRoomVisibility());
                dispatch(setCurrentValue([props.data]));
                dispatch(setCurrentValueIndex(props.valueIndex));
                dispatch(
                  setScenarioDescription([scenarioDescriptionList[index]])
                );
              }}
            >
              {/* <Button variant="outlined" color="primary">
                {scenarioValue}
              </Button> */}
              <Checkbox
                radio
                checked={isSelected}
                label={scenarioValue}
              // key={id}
              />
              <button
                style={{ background: "none", border: "none" }}
                onClick={() => {
                  dispatch(toggleInfoBpx([scenarioValue]));
                }}
              >
                <i
                  class="info circle icon"
                  style={{ marginLeft: "5px", color: "blue" }}
                ></i>
              </button>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};


export default DropDownEnergyData;
