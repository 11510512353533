import React, { useEffect, useState } from "react";
import * as THREE from "three";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
// import shadingProgressionData from "../rawDataNotImplemented/Project8ShadingProgression";
// import shadingProgressionProject52 from "../rawDataNotImplemented/Project52ShadingProgression";
// import shadingProgressionProject50 from "../rawDataNotImplemented/Project50ShadingProgression";
// import shadingProgressionProject15_29 from "../rawDataNotImplemented/Project15_29ShadingProgression";
// import shadingProgressionProject56 from "../rawDataNotImplemented/Project56ShadingProgression";
// import shadingProgressionProject3 from "../rawDataNotImplemented/Project3ShadingProgression";
// import shadingProgressionProject376 from "../rawDataNotImplemented/Project376ShadingProgression";
// import shadingProgressionProject1289 from "../rawDataNotImplemented/Project1289ShadingProgression";
// import { setTargetElementShow } from "../../redux/sceneSlice";
import { setTrue, toggleBoxForClickOutsideOfBox, updateWindowPosition } from "../../redux/windowConfiguration";
import { getOldProjectId } from "../utility/hardconding";
import { setShadingProgressionIsLoading } from "../../redux/shadingSystem";
import axios from "axios";
import { getIthStepHourAndMinute, getFormattedHourAndMinuteStr } from "../utils";
import { reduxLocalStorage } from "../utility/storage";

// export const getRelavantHardcodedData = (oldProjectId, newProjectId) => {
//   if (oldProjectId == 8) return shadingProgressionData;
//   if (oldProjectId == 52) return shadingProgressionProject52;
//   if (oldProjectId == 15 || oldProjectId == 29) return shadingProgressionProject15_29;
//   if (oldProjectId == 50) return shadingProgressionProject50;
//   if (oldProjectId == 56) return shadingProgressionProject56;
//   if (oldProjectId == 3) return shadingProgressionProject3;
//   if (newProjectId == 376) return shadingProgressionProject376
//   if (newProjectId == 1289) return shadingProgressionProject1289
//   return [];
// };

const ShadingProgression = () => {
  const dispatch = useDispatch();
  var shadingDemandSelector = useSelector((state) => state.shadingSystem.value);
  const project_id = reduxLocalStorage.getItem("project_id");
  const oldProjectId = getOldProjectId(project_id)
  const meshMaterial = new THREE.MeshBasicMaterial({
    color: "#70b0e0",
    side: THREE.DoubleSide,
  });
  const meshMaterial1 = new THREE.MeshBasicMaterial({
    color: "#304966",
    side: THREE.DoubleSide,
  });
  const clickedWindowMeshMaterial = new THREE.MeshBasicMaterial({
    color: "#ff0",
    side: THREE.DoubleSide,
  });
  const token = localStorage.getItem("token");
  const ref = useRef();
  const visibilityGLTF = useSelector(
    (state) => state.shadingSystem.selectedHours
  );
  var clickedWindowId = useSelector(
    (state) => state.shadingConfigurationWindow.name
  );

  var allProejctParameters = useSelector((state) => state.sceneVar);
  const shadingProgressionStep = allProejctParameters.shadingProgressionStep
  const shadingProgressionDate = allProejctParameters.shadingProgressionDate

  const [resultArray, setResultArray] = useState([]);
  let url = `${process.env.REACT_APP_API_URL_2}api/shading_progression?project_id=${project_id}`
  const [success, setSuccess] = useState(false)
  const visible = shadingDemandSelector.checkStatus[2]

  useEffect(() => {
    if (visible && !success && shadingProgressionDate && shadingProgressionStep) {
      dispatch(setShadingProgressionIsLoading(true));
      axios.get(url, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        const transformedData = response.data?.map(row => {
          const transformedRow = { ID: row.ID, polygon: row.polygon }

          const [_, month, day] = shadingProgressionDate?.split("-");
          row.shading_progression_data?.forEach(timeIndex => {
            const { hours: formattedHour, minutes: formattedMinute } = getFormattedHourAndMinuteStr(getIthStepHourAndMinute(shadingProgressionStep, timeIndex))
            transformedRow[`${month}-${day} ${formattedHour}:${formattedMinute}`] = 1
          })
          return transformedRow
        })
        setSuccess(true)
        setResultArray(transformedData);
      }).finally(() => {
        dispatch(setShadingProgressionIsLoading(false));
      })
    }
  }, [visible]);

  return (
    <>
      <group
        rotation-x={Math.PI * -0.5}
        ref={ref}
        visible={visible}
      >
        {resultArray?.map((data, i) => {
          return (
            <>
              <mesh
                dispose={null}
                material={
                  data?.ID === clickedWindowId ? clickedWindowMeshMaterial :
                    data[visibilityGLTF] == 1 ? meshMaterial : meshMaterial1
                }
                onClick={(config) => {
                  if (!visible) return;
                  dispatch(toggleBoxForClickOutsideOfBox());
                  dispatch(setTrue());
                  dispatch(
                    updateWindowPosition([
                      null,
                      null,
                      data.ID,
                      null,
                    ])
                  );
                  config.stopPropagation();
                }}
              >
                <bufferGeometry>
                  <bufferAttribute
                    attach="attributes-position"
                    array={new Float32Array(data.polygon)}
                    count={new Float32Array(data.polygon).length / 3}
                    itemSize={3}
                  />
                </bufferGeometry>
              </mesh>
            </>
          );
        })}
      </group>
    </>
  );
};

export default ShadingProgression;
