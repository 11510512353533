import chroma from "chroma-js";
import * as THREE from "three";
import CryptoJS from "crypto-js";

export function normalize(val, max, min) {
  if (max - min === 0) return 1; // or 0, it's up to you
  return (val - min) / (max - min);
}
export const f = chroma.scale([
  "0a6d0a",
  "137845",
  "07ed7a",
  "82ed07",
  "ede907",
  "ed7607",
  "ed0707",
  "3e0303",
]);
export const formatNumber = (num) => {
  const [whole, fraction] = num.toFixed(2).split(".");
  const wholeWithDots = [...whole]
    .reverse()
    .join("")
    .replace(/(\d{3})/g, "$1.")
    .split("")
    .reverse()
    .join("");

  const cleanedWholeWithDots = wholeWithDots.startsWith(".")
    ? wholeWithDots.substring(1)
    : wholeWithDots;

  return `${cleanedWholeWithDots},${fraction}`;
};
export const windDirectionScale = chroma.scale([
  "1607ED",
  "07B7ED",
  "07ED7A",
  "82ED07",
  "EDE907",
  "ED7607",
  "ED0707",
  "3e0303",
]);

export const sunHoursScale = chroma.scale([
  "0087FF",
  "416CF5",
  "5D5FEF",
  "825FDE",
  "A95FCB",
  "EF5DA8",
]);

export const sensorPlacementScale = chroma.scale([
  "a70226",
  "ca2326",
  "de3f2e",
  "fba25b",
  "d1e311",
  "45ad5a",
  "219b51",
  "0f8445",
  "006837",
]);

export const windTurbineScale = sensorPlacementScale

export const roomEnergyDataScale = chroma.scale([
  "07b7ed",
  "07ed7a",
  "07ED7A",
  "82ed07",
  "ede907",
  "ed7607",
  "ed0707",
  "bd0505",
]);

export const daylightFactorScale = chroma.scale(["FF0000", "FFFF00", "00FF00"]);

export const pvYieldScale = chroma.scale([
  "a70226",
  "ca2326",
  "de3f2e",
  "fba25b",
  "d1e311",
  "45ad5a",
  "219b51",
  "0f8445",
  "006837",
]);

export const roomEnergyDataInfoBoxScale = chroma.scale([
  "80ff80",
  "66ff66",
  "1aff1a",
  "00cc00",
  "009900",
  "008000",
  "006600",
  "004d00",
]);

export function generateColor(value, minv, maxv, colorScale) {
  if (!value && value != 0) return 'black'
  const value1 = normalize(value, maxv, minv);
  const value2 = colorScale(value1);
  const rgb = value2._rgb;
  const color = `rgb(${Math.floor(rgb[0])}, ${Math.floor(rgb[1])}, ${Math.floor(
    rgb[2]
  )})`;
  return color;
}

export const roomTypesColors = [
  "red",
  "blue",
  "#00a86b", //Jade
  "yellow",
  "brown",
  "#b200ed", //Violet
  "pink",
  "orange",
  "gray",
  "#fa8072", //Salmon
  "#000080", //Navy
  "#4cbb17", //Kelly
  // "#fffdd0", //Creame
  "#2b1700", //Chocolate
  "#6f2da8", //Grape
  "#ff6fff", //Ultra

  "#008ecc", //Olympic
  "#043927", //Sacramento
  "#6693f5", //Corn Flower
  "#311432", //Eggplant
];

export const getClickedRoomColor = () => "#ff0";
export const getClickedWindowColor = () => "#ff0";

export const getRoomTypeColor = (roomType, allRoomTypes) => {
  const colorIndex = allRoomTypes?.indexOf(roomType);
  if (colorIndex !== -1) return roomTypesColors[colorIndex];
};

export const generateMinMax = (valueList, sensorType) => {
  const values = valueList.map((item) => item[sensorType]);
  return {
    minVal: Math.min(...values),
    maxVal: Math.max(...values),
  };
};
export const calculateColor = (value, minValue, maxValue, colorBar) => {
  if (value === 0.621) {
    const normalizedValue = (value - minValue) / (maxValue - minValue);
  }
  const normalizedValue = (value - minValue) / (maxValue - minValue);
  return chroma.scale(colorBar)(normalizedValue).hex();

  // return chroma.scale(colorBar)(normalizedValue).css();
};

export function generateNumbers(min, max, step) {
  const numbers = [];
  for (let i = min; i <= max; i += step) {
    numbers.push(i);
  }
  return numbers;
}

export function calculatePosition(num, min, max) {
  return ((num - min) / (max - min)) * 100;
}
export function generatePolygon(data) {
  return data.map((k) => new THREE.Vector3(k[0], k[1], k[2]));
}

export const generateAxisValues = (minValue, maxValue, step) => {
  if (minValue === undefined || maxValue === undefined) return;
  let bestStep;
  const range = maxValue - minValue;
  if (step !== undefined) bestStep = step;
  else {
    const predefinedSteps = [
      0.001,
      0.002,
      0.005,
      0.01,
      0.02,
      0.05,
      0.1,
      0.2,
      0.5,
      1,
      2,
      5,
      10,
      25,
      50,
      100,
      250,
      500,
      1000,
      2000,
      5000,
      10000,
      25000,
      50000,
      100000,
      250000,
      500000,
    ];
    const ticksNos = [...Array(8).keys()].map((e) => e + 8);
    const upperBounds = predefinedSteps.map((step) => {
      for (const tickNo of ticksNos) {
        if (step * tickNo > range) return step * tickNo;
      }
      return null;
    });
    const bestStepIndex = upperBounds.indexOf(
      Math.min(...upperBounds.filter((e) => e !== null))
    );
    bestStep = predefinedSteps[bestStepIndex];
  }
  if (bestStep === undefined || bestStep === null) return;
  const axisValues = [];
  const roundedMinValue = Math.floor(minValue / bestStep) * bestStep;
  for (let i = 0; i <= Math.ceil(range / bestStep); i++) {
    axisValues.push(roundedMinValue + i * bestStep);
  }
  return axisValues;
};

export const genratePolygon = (data) => {
  var output = [];
  data.forEach((k) => {
    output.push(new THREE.Vector3(k[0], k[1], k[2]));
  });
  return output;
};

const secretPass = "XkhZG4fW2t2W";

export const encryptData = (text) => {
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    "secretPass"
  ).toString();
  return data;
};
export const decryptData = (text) => {
  const bytes = CryptoJS.AES.decrypt(text, "secretPass");
  const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return data;
};

export const hasShadingProgression = (project_id, newProjectId) => {
  if (!project_id && !newProjectId) return false
  let output = false
  if (project_id) {
    if ([3, 8, 15, 29, 50, 52, 56].includes(Number(project_id)))
      output = true
  }
  if (newProjectId) {
    if ([376, 1289].includes(Number(newProjectId)))
      output = true
  }
  return output;
};


export const monthsOrder = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",]


export const arraySum = (arr) => {
  return arr?.reduce((total, current) => total + current, 0)
}

export const getIthStepHourAndMinute = (step, timeIndex,) => {
  const totalMinutes = (timeIndex) * step;

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return { hours, minutes }
}

export const getFormattedHourAndMinuteStr = ({ hours, minutes }) => {

  const formattedHour = String(hours).padStart(2, "0");
  const formattedMinute = String(minutes).padStart(2, "0");

  return { hours: formattedHour, minutes: formattedMinute }
}


export const generateTimeArray = (date, step) => {
  const result = [];
  const [_, month, day] = date.split("-");
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += step) {
      const { hours: formattedHour, minutes: formattedMinute } = getFormattedHourAndMinuteStr({ hours: hour, minutes: minute })
      result.push(`${month}-${day} ${formattedHour}:${formattedMinute}`);
    }
  }
  return result;
}