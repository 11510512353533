import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { hideWindRisk } from "../../redux/infoWindRisk";
import InfoBoxDashboardContainer from "./InfoBoxDashboard";
import { generalNumbersFormatter } from "../utility/charts";

const WindRiskInfoBox = () => {
  const {
    firstWindIsShowing: windRiskSelector,
    value: infoBoxWindRiskSelector,
    radarAvg: infoBoxWindRiskSelectorRadar,
    radarMax: infoBoxWindRiskSelectorRadarMax,
    time_velocity_more_than_12: timeVelocityMoreThanTwelve,
    name,
    x: coordinateX,
    y: coordinateY,
    z: coordinateZ,
  } = useSelector((state) => state.infoWindRisk);
  const { value: windRiskIsShowing } = useSelector(
    (state) => state.shadingSystem
  );
  const windRiskDataIsAvailable = useSelector(
    (state) => state.sceneVar.windRisk
  );
  var allProejctParameters = useSelector((state) => state.sceneVar);

  // const labels = getLabels(project_id);
  const labels = allProejctParameters?.windRiskDirectionsList;
  const output = labels?.map((label, item) => ({
    name: label,
    AVG: infoBoxWindRiskSelectorRadar[item],
    MAX: infoBoxWindRiskSelectorRadarMax[item],
  }));

  const dispatch = useDispatch();
  const refOne = useRef(null);

  return (
    windRiskIsShowing.checkStatus[0] &&
    windRiskSelector &&
    windRiskDataIsAvailable && (
      <InfoBoxDashboardContainer
        handleClickOutside={() => dispatch(hideWindRisk())}
        needsCapture
        captureProps={{
          targetElement: () => refOne?.current,
        }}
        style={{
          display: 'block',
          width: "800px",
          height: "400px",
        }}
      >
        <div
          ref={refOne}
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            paddingLeft: "40px",
            paddingTop: "20px",
          }}
        >
          <div >
            <p style={{ fontWeight: "bold" }}>Wind Frequency Radar Chart</p>
            <ResponsiveContainer width={400} height="90%" margineTop="20px">
              <RadarChart
                height={400}
                width={400}
                padding="4px"
                outerRadius="80%"
                data={output}
              >
                <Legend />
                <PolarGrid />
                <PolarAngleAxis dataKey="name" tick={{ fontSize: 14 }} />
                <PolarRadiusAxis domain={[0, 2]} tickCount={9} tick={{ fontSize: 12, fontWeight: 500 }} angle={10} />
                <Tooltip formatter={generalNumbersFormatter} />
                <Radar
                  dataKey="AVG"
                  stroke="#00a010"
                  fill="#00d010"
                  fillOpacity={0.7}
                />
                <Radar
                  dataKey="MAX"
                  stroke="#3385ff"
                  fill="#3385ff"
                  fillOpacity={0.2}
                />
              </RadarChart>
            </ResponsiveContainer>
          </div>
          <div
            style={{
              marginLeft: "50px",
            }}
          >
            <p style={{ fontWeight: "bold" }}>wind risk info</p>
            <div>
              {"Counting critical wind hours (>10 m/s )"} :{" "}
              {timeVelocityMoreThanTwelve} hours
            </div>
            <div>name : {name}</div>
            <div>coordinate x : {coordinateX}</div>
            <div>coordinate y : {coordinateY}</div>
            <div>coordinate z : {coordinateZ}</div>
          </div>
        </div>
      </InfoBoxDashboardContainer>
    )
  );
};

export default WindRiskInfoBox;
