import React from "react";
import * as THREE from "three";
import { useSelector, useDispatch } from "react-redux";
import {
  generateColor,
  getClickedRoomColor,
  roomEnergyDataScale,
} from "../utils";

import {
  roomConfigurationStateChanger,
  setEnergyBoxView,
  updateRoomEnergyConfigurationPosition,
} from "../../redux/roomData";
import {
  roomGeneralSetTrue,
  updateRoomConfigurationPosition,
} from "../../redux/windowConfiguration";
import { Edges } from "@react-three/drei";
import { isOptionSelected } from "../utility/helpers";
import { reduxLocalStorage } from "../utility/storage";
function genratePolygon(data) {
  var output = [];
  data.forEach((k) => {
    output.push(new THREE.Vector3(k[0], k[1], k[2]));
  });
  return output;
}

const RoomEnergyData = () => {
  const dispatch = useDispatch();
  const selectedFloor = useSelector(
    (state) => state.floorVisibility.selectedFloors
  );

  const minMaxDict = useSelector((state) => state.roomData.rangeValues);
  const haveRange = useSelector((state) => state.roomData.haveRange);
  const getLocalStorageItem = (key) => {
    const item = reduxLocalStorage.getItem(key);
    return item ? item.split(",") : [];
  };

  const floorsList = getLocalStorageItem("floor_list");
  const scenarioList = getLocalStorageItem("scenario_list");
  let energyValueData = getLocalStorageItem("value_list");

  const selector = useSelector((state) => state.roomData.value);

  const isApiFinished = useSelector((state) => state.roomData.api.isFinished);
  const data = useSelector((state) => state.roomData.api.data);
  if (isApiFinished == true) {
    for (let valueList = 0; valueList < energyValueData.length; valueList++) {
      for (
        let scenarioVal = 0;
        scenarioVal < scenarioList.length;
        scenarioVal++
      ) {
        for (let i = 0; i < data.length; i++) { }
      }
    }
  }
  const colorbars = useSelector((state) => state.colorbars["Energy Data"]);

  var clickedRoomId = useSelector(
    (state) => state.shadingConfigurationWindow.roomId
  );
  const roomIsClicked = useSelector(
    (store) => store.roomData.roomConfigurationState
  );
  return (
    <>
      {isApiFinished && haveRange && data?.length > 0 && (
        <>
          {data.map((eachFloor, floorIndex) => {
            if (!Array.isArray(eachFloor)) return
            return energyValueData.map((value, valueIndex) => {
              const { max, min } = colorbars[value] || {
                max: minMaxDict[value]?.max,
                min: minMaxDict[value]?.min,
              };
              return scenarioList.map((scenario, scenarioIndex) => {
                return (
                  <group rotation-x={Math.PI * -0.5}>
                    {

                      eachFloor.map((room) => {
                        let roomEnergyValue
                        try {
                          roomEnergyValue = JSON.parse(room.values)[0][valueIndex][
                            value
                          ][scenarioIndex]
                        } catch { }

                        const isVisible =
                          selectedFloor[floorIndex] &&
                          isOptionSelected({
                            selector,
                            parentName: value,
                            childName: scenario,
                          });

                        return (
                          <>
                            <mesh
                              position={[0, 0, JSON.parse(room.coordinates)[2]]}
                              visible={isVisible}
                              onClick={(config) => {
                                if (isVisible) {
                                  // dispatch(setEnergyBoxView(true));
                                  dispatch(roomConfigurationStateChanger(true));
                                  dispatch(
                                    updateRoomConfigurationPosition([
                                      config.pageX,
                                      config.pageY,
                                      room.text,
                                      room.id,
                                      room,
                                    ])
                                  );
                                  dispatch(roomGeneralSetTrue());

                                  // dispatch(
                                  //   updateRoomEnergyConfigurationPosition([
                                  //     config.pageX,
                                  //     config.pageY,
                                  //     room.text,
                                  //     room.room_area,
                                  //     room.coordinates,
                                  //     JSON.parse(room.values)[0][valueIndex][
                                  //       value
                                  //     ],
                                  //     room.room_function_value,
                                  //   ])
                                  // );
                                  config.stopPropagation();
                                }
                              }}
                            >
                              <primitive
                                object={
                                  new THREE.ShapeGeometry(
                                    new THREE.Shape(
                                      genratePolygon(
                                        JSON.parse(room.room_polygon)
                                      )
                                    )
                                  )
                                }
                                attach={"geometry"}
                              />
                              {roomIsClicked && clickedRoomId === room.id && (
                                <Edges color="#000" />
                              )}

                              <meshBasicMaterial
                                side={THREE.DoubleSide}
                                color={
                                  roomIsClicked && clickedRoomId === room.id
                                    ? getClickedRoomColor()
                                    : generateColor(
                                      roomEnergyValue,
                                      min,
                                      max,
                                      roomEnergyDataScale
                                    )
                                }
                              />
                            </mesh>
                          </>
                        );


                      })}
                  </group>
                );
              });
            });
          })}
        </>
      )}
    </>
  );
};

export default RoomEnergyData;
