import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Asset from "./Asset";
import { setloading } from "../../redux/sceneSlice";
import { reduxLocalStorage } from "../utility/storage";

export default function Stairs(props) {
  const project_id = reduxLocalStorage.getItem("project_id");
  const selectedFloor = useSelector(
    (state) => state.floorVisibility.selectedFloors
  );
  const dispatch = useDispatch();
  var stairsList = useSelector((state) => state.sceneVar.stairsList);
  var targetBuildingScene = useSelector(
    (state) => state.sceneVisibility.value[7]
  );
  return (
    stairsList && (
      <group name="stairs" visible={targetBuildingScene}>
        {stairsList.map((floor, index) => {
          return (
            <>
              <Asset
                url={`${process.env.REACT_APP_API_URL}project/glb/?project_id=${project_id}&type=${floor}`}
                visible={selectedFloor[index]}
                color={"#d6f099"}
                onFulfilled={
                  index === stairsList.length - 1
                    ? () => dispatch(setloading({ index: 7, value: false }))
                    : null
                }
                onLoading={() =>
                  dispatch(setloading({ index: 7, value: true }))
                }
              />
            </>
          );
        })}
      </group>
    )
  );
}
