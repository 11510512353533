import { useDispatch, useSelector } from "react-redux";
import { decryptData, encryptData } from "./utils";
import { useEffect } from "react";
import { updateUser } from "../redux/user";
import { reduxLocalStorage } from "./utility/storage";

const Authenticator = () => {
  const { user } = useSelector((store) => store.user);
  const a = (x) => encryptData(x);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!user)
      try {
        const payload = decryptData(
          window.location.search.replace("?project=", "")
        );
        dispatch(
          updateUser({
            project_id: payload.project_id,
            token: payload.token,
            role: payload.role,
            username: payload.username,
            password: payload.password,
            isAuthenticated: true,
          })
        );
        reduxLocalStorage.setItem("project_id", payload.project_id);
        localStorage.setItem("token", payload.token);
      } catch {
        dispatch(updateUser({ isAuthenticated: false, isNotAuthorized: true }));
      }
  }, []);
};

export default Authenticator;
