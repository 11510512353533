import AirIcon from "@mui/icons-material/Air";
import ApartmentIcon from "@mui/icons-material/Apartment";
import BoltIcon from "@mui/icons-material/Bolt";
import CircleIcon from "@mui/icons-material/Circle";
import ForestIcon from "@mui/icons-material/Forest";
import MovingIcon from "@mui/icons-material/Moving";
import RadarIcon from "@mui/icons-material/Radar";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import TableRowsIcon from "@mui/icons-material/TableRows";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import { Chip } from "@mui/material";
import React from "react";
import { MdRollerShades } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { deselectDaylight } from "../redux/daylightFactor";
import { setDefaultFloor } from "../redux/floorVisibility";
import { deselectPVData } from "../redux/pvData";
import {
  deselectEnergyData
} from "../redux/roomData";
import { resetChosenFilter } from "../redux/sceneGlobal";
import {
  selectAllSurrounding,
  setDefault,
  setDefaultTarget,
} from "../redux/sceneSlice";
import {
  deselectSensorPosition,
  deselectShadingProgression,
  deselectWindRisk,
  resetSensorPlacement
} from "../redux/shadingSystem";
import { setDefaultVirtualSensors } from "../redux/solarSensors";
import { deselectWindSensors } from "../redux/windDirection";
import { useSensorPlacementColorBar } from "./CustomHooks";

const Filter = () => {
  var dispatch = useDispatch();
  var daylightSelector = useSelector((state) => state.daylightFactor.value);
  var roomDataSelector = useSelector((state) => state.roomData.value);
  var windSelector = useSelector((state) => state.windDirection.value);
  var energyGenerationSelector = useSelector((state) => state.pvData.value);
  const sensorsSelector = useSelector((store) => store.sensorSolars.value);
  var windRiskSelector = useSelector(
    (state) => state.shadingSystem.value.checkStatus
  );
  const surroundingVisibility = useSelector(
    (state) => state.sceneVisibility.surroundingValue
  );
  const floorVisibility = useSelector(
    (state) => state.floorVisibility.selectedFloors
  );
  var targetScene = useSelector((state) => state.sceneVisibility);
  var shadingDemandSelector = useSelector((state) => state.shadingSystem.value);

  const { filter } = useSelector((state) => state.pvData);
  const { isVisible: isSensorPlacementSelected } = useSensorPlacementColorBar()



  const handleClick = () => {
    dispatch(deselectDaylight());
    dispatch(deselectEnergyData());
    dispatch(setDefault());
    dispatch(setDefaultFloor());
    dispatch(setDefaultVirtualSensors());
    dispatch(resetChosenFilter());
    dispatch(resetSensorPlacement());
    dispatch(deselectWindSensors());
    dispatch(deselectPVData());
  };

  const chips = [
    {
      label: "Target Building",
      onDelete: () => dispatch(setDefaultTarget()),
      icon: <ApartmentIcon />,
      isVisible:
        targetScene.value.some((e) => !e) &&
        targetScene.value?.some((e, i) => e !== targetScene.default[i]),
      deleteIcon: <RestartAltIcon />,
      color: "error",
    },
    {
      label: "Surrounding",
      onDelete: () => dispatch(selectAllSurrounding()),
      icon: <ForestIcon />,
      isVisible: surroundingVisibility?.some((e) => !e),
      deleteIcon: <RestartAltIcon />,
      color: "error",
    },
    {
      label: "Floors",
      onDelete: () => dispatch(setDefaultFloor()),
      icon: <TableRowsIcon />,
      isVisible: floorVisibility?.some((e) => !e),
      deleteIcon: <RestartAltIcon />,
      color: "error",
    },

    {
      label: "Solar Sensors",
      onDelete: () => dispatch(setDefaultVirtualSensors()),
      icon: <CircleIcon />,
      isVisible: sensorsSelector?.checkStatus?.some((e) => e),
      color: "error",
    },
    {
      label: "Wind Sensors",
      onDelete: () => dispatch(deselectWindSensors()),
      icon: <MovingIcon />,
      isVisible: windSelector.checkStatus.some((e) => e),
      color: "error",
    },
    {
      label: "Wind Risk",
      onDelete: () => dispatch(deselectWindRisk()),
      icon: <AirIcon />,
      isVisible: windRiskSelector[0],
      color: "error",
    },
    {
      label: "Sensor Position",
      onDelete: () => dispatch(deselectSensorPosition()),
      icon: <RadarIcon />,
      isVisible: isSensorPlacementSelected,
      color: "error",
    },
    {
      label: "Energy Data",
      onDelete: () => dispatch(deselectEnergyData()),
      icon: <BoltIcon />,
      isVisible:
        roomDataSelector?.parentName &&
        roomDataSelector?.childName !== undefined,
      color: "error",
    },

    {
      label: "Daylight Factor",
      onDelete: () => dispatch(deselectDaylight()),
      icon: <WbSunnyIcon />,
      isVisible:
        daylightSelector?.parentName &&
        daylightSelector?.childName !== undefined,
      color: "error",
    },
    {
      label: "PV Yield",
      onDelete: () => dispatch(deselectPVData()),
      icon: <SolarPowerIcon />,
      isVisible: Object.values(filter)?.some((e) => e),
      color: "error",
    },
    {
      label: "Shading Progression",
      onDelete: () => dispatch(deselectShadingProgression()),
      icon: <MdRollerShades size="1.5rem" />,
      isVisible: shadingDemandSelector.checkStatus[2],
      color: "error",
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        alignItems: "center",
        gap: "16px",
        top: "50px",
        zIndex: 10,
      }}
    >
      <button
        style={{
          top: "40px",
          position: "absolute",
          top: "58px",
          left: "10px",
          border: "1px solid #d33",
          color: "#d33",
          borderRadius: "7px",
          width: "148px",
          height: "40px",
          alignItems: "center",
          padding: "4px 4px 4px",
          background: "#FFFFFF",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        Reset Filters
      </button>
      <div
        style={{
          display: "flex",
          position: "absolute",
          // left: vv.length > 0 ? "496px" : "190px",
          left: "190px",
          gap: "8px",
          top: "78px",
          height: "100%",
          alignItems: "center",
        }}
      >
        {chips.map((chip, index) => {
          if (chip.isVisible)
            return (
              <Chip
                key={index}
                label={chip.label}
                icon={chip.icon}
                variant="outlined"
                onDelete={chip.onDelete}
                // onClick={chip.onClick}
                deleteIcon={chip.deleteIcon}
                color={chip.color}
                sx={{ background: "white" }}
              />
            );
        })}
        {shadingDemandSelector.checkStatus[2] && (
          <div
            style={{
              width: "200px",
              border: "1px solid #eee",
              borderRadius: "8px",
              backgroundColor: "white",
              padding: "4px 12px",
              height: "48px",
              marginLeft: 4,
              marginTop: 4,
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: 12,
                  height: 6,
                  backgroundColor: "#70b0e0",
                  borderRadius: 4,
                }}
              />
              <p>Exposed to sunlight</p>
            </div>
            <div
              style={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: 12,
                  height: 6,
                  backgroundColor: "#304966",
                  borderRadius: 4,
                }}
              />
              <p>Not exposed to sunlight</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Filter;
