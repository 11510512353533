import React, { useState, useEffect, useMemo } from "react";
import SunHours from "./SunHours";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  setSensorsIsLoading,
  updateRangeSensorSolar,
} from "../../redux/solarSensors";
import { windDirectionScale, sunHoursScale, generateMinMax } from "../utils";
import { SphereGeometry } from "three";
import { Spinner } from "../spinner";
import { Html } from "@react-three/drei";
import { reduxLocalStorage } from "../utility/storage";

const types = [
  "sunshine_hours",
  "direct_energy",
  "diffuse_energy",
  "global_energy",
];

const SolarSensors = () => {
  const geometry = useMemo(() => new SphereGeometry(0.26, 20, 10), []);
  const sensorsSelection = useSelector(
    (store) => store.sensorSolars.value.checkStatus
  );
  const project_id = reduxLocalStorage.getItem("project_id");
  const dispatch = useDispatch();
  var sensorSolars = useSelector((state) => state.sensorSolars.value);
  const display = sensorSolars.checkStatus.some((e) => e);

  const [resultArray, setResultArray] = useState([]);
  // const [nodes, setNodes] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      dispatch(setSensorsIsLoading(true));
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/sensorslight/?project_id=${project_id}`
      );
      if (response.status === 200) {
        // setNodes(true);

        const minMaxValues = types.flatMap((type) => {
          const { minVal, maxVal } = generateMinMax(response.data, type);
          return [minVal, maxVal];
        });
        dispatch(updateRangeSensorSolar(minMaxValues));
        setResultArray(response.data);
      }
      dispatch(setSensorsIsLoading(false));
    };
    if (display & (resultArray.length === 0)) fetchData();
  }, [display]);
  if (resultArray.length > 0)
    return (
      <>
        {[
          ["sun hours", "sunshine_hours", 0, 1],
          ["direct energy", "direct_energy", 2, 3],
          ["diffuse energy", "diffuse_energy", 4, 5],
          ["global energy", "global_energy", 6, 7],
        ].map(([label, val, minIdx, maxIdx], idx) => {
          if (sensorsSelection[idx])
            return (
              <SunHours
                key={idx}
                name={label}
                sensorRow={resultArray}
                sunval={val}
                colorBar={
                  val === "sunshine_hours" ? sunHoursScale : windDirectionScale
                }
                minIdx={minIdx}
                maxIdx={maxIdx}
                id_val={idx.toString()}
              />
            );
        })}
      </>
    );
};

export default SolarSensors;
