import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Asset from "./Asset";
import { setloading } from "../../redux/sceneSlice";
import { reduxLocalStorage } from "../utility/storage";
function Rooftop(props) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const project_id = reduxLocalStorage.getItem("project_id");
  const dispatch = useDispatch();
  const roofTop = useSelector((state) => state.sceneVar.roofTop);
  var targetBuildingScene = useSelector(
    (state) => state.sceneVisibility.value[8]
  );
  return (
    roofTop && (
      <group>
        <Asset
          url={`${apiUrl}project/glb/?project_id=${project_id}&type=${roofTop}`}
          visible={targetBuildingScene}
          color="#3ec762"
          onFulfilled={() => dispatch(setloading({ index: 8, value: false }))}
          onLoading={() => dispatch(setloading({ index: 8, value: true }))}
        />
      </group>
    )
  );
}
export default React.memo(Rooftop);
