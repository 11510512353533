import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Asset from "./Asset";
import { setloading } from "../../redux/sceneSlice";
import { reduxLocalStorage } from "../utility/storage";

function ExternalWalls(props) {
  const project_id = reduxLocalStorage.getItem("project_id");
  const dispatch = useDispatch();
  const selectedFloor = useSelector(
    (state) => state.floorVisibility.selectedFloors
  );
  var targetBuildingScene = useSelector(
    (state) => state.sceneVisibility.value[1]
  );

  var facadeList = useSelector((state) => state.sceneVar.facadeList);
  return (
    <group name="external walls" visible={targetBuildingScene}>
      {facadeList.map((floor, index) => {
        return (
          <>
            <Asset
              url={`${process.env.REACT_APP_API_URL}project/glb/?project_id=${project_id}&type=${floor}`}
              visible={targetBuildingScene && selectedFloor[index]}
              color={"#defced"}
              onFulfilled={
                index === facadeList.length - 1
                  ? () => dispatch(setloading({ index: 1, value: false }))
                  : null
              }
              onLoading={() => dispatch(setloading({ index: 1, value: true }))}
            />
          </>
        );
      })}
    </group>
  );
}

export default React.memo(ExternalWalls);
