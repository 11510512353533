import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deselectAllPVs, setTargetElementShow } from "../redux/sceneSlice";
import { deselectPVData } from "../redux/pvData";

export const useColorBar = (category, subcategry, dataMin, dataMax) => {
  const colorbar = useSelector((state) => state.colorbars[category]);
  const { max, min } = colorbar[subcategry] || {
    min: dataMin,
    max: dataMax,
  };
  return { min, max };
};

export const useHandleWindowsSwitch = () => {
  const dispatch = useDispatch();
  var shadingDemandSelector = useSelector((state) => state.shadingSystem.value);
  var windRiskSelector = useSelector((state) => state.shadingSystem.value);
  useEffect(() => {
    const windriskIsSelected = windRiskSelector.checkStatus[0];
    const shadingProgressionIsSelected = shadingDemandSelector.checkStatus[2];
    const isWindowVisible =
      !shadingProgressionIsSelected && !windriskIsSelected;
    dispatch(setTargetElementShow({ index: 4, value: isWindowVisible }));
  }, [shadingDemandSelector.checkStatus[2], windRiskSelector.checkStatus[0]]);
};

export const useHandlePVsSwitch = () => {
  const dispatch = useDispatch();
  const { pvFilter: pvPanelsFilter } = useSelector((state) => state.sceneVisibility);
  const { filter: pvDataFilter } = useSelector((state) => state.pvData);
  useEffect(() => {
    const pvDataIsSelected = Object.values(pvDataFilter)?.some(e => e)
    if (pvDataIsSelected)
      dispatch(deselectAllPVs());
  }, [pvDataFilter]);

  useEffect(() => {
    const pvPanelsIsSelected = Object.values(pvPanelsFilter)?.some(e => e)
    if (pvPanelsIsSelected)
      dispatch(deselectPVData());
  }, [pvPanelsFilter]);
};


export const useAllRoomsNames = () => {
  const data = useSelector((state) => state.roomData.api.data);
  const roomsNames = [];
  data?.forEach((floor) => {
    if (!Array.isArray(floor)) return
    floor?.forEach((room) => {
      roomsNames.push(room.text);
    });
  });
  return roomsNames;
};

export const useUniqueRoomsFunctions = () => {
  const data = useSelector((state) => state.roomData.api.data);
  const roomsFunctions = [];
  data?.forEach((floor) => {
    if (!Array.isArray(floor)) return
    floor?.forEach((room) => {
      if (!roomsFunctions.includes(room.room_function_value))
        roomsFunctions.push(room.room_function_value);
    });
  });
  return roomsFunctions;
};

export const useSensorPlacementColorBar = () => {
  var groupZone = useSelector((state) => state.shadingSystem.zoneGroup);
  var windRiskSelector = useSelector(
    (state) => state.shadingSystem.value.checkStatus
  );

  return {
    isVisible:
      groupZone.some(e => e) && (windRiskSelector.slice(3).some(e => true)),
  };
};


export const useWindTurbineColorBar = () => {
  var windTurbine = useSelector((state) => state.windTurbine);
  try {
    var { value: { checkStatus: selectedZones }, valuesRanges: { aep_kwh: [minValue, maxValue] } } = windTurbine
    const isVisible = Object.values(selectedZones).some(e => e)
    return { minValue, maxValue, isVisible };
  } catch {
    return { minValue: null, maxValue: null, isVisible: false }

  }
};

export const useSolarSensorsColorBar = () => {
  var solarSensorSelector = useSelector((state) => state.sensorSolars);

  const visiblity = solarSensorSelector.value.checkStatus;
  const visibleIndex = visiblity.indexOf(true);

  const min = solarSensorSelector.rangeList[visibleIndex * 2];
  const max = solarSensorSelector.rangeList[visibleIndex * 2 + 1];
  const isVisible = visibleIndex !== -1;
  return { visibleIndex, min, max, isVisible };
};

export const useWindRiskColorBar = () => {
  const { max_time_velocity_more_than_12: maxTimeVelMoreThan12 } = useSelector(
    (state) => state.infoWindRisk
  );
  var windRiskIsShowing = useSelector((state) => state.shadingSystem.value);
  const windRiskDataIsAvailable = useSelector(
    (state) => state.sceneVar.windRisk
  );
  const isVisible = windRiskIsShowing.checkStatus[0] && windRiskDataIsAvailable;
  return { isVisible, maxTimeVelMoreThan12 };
};

export const useEnergyDataColorBar = () => {
  var colorBarVisibility = useSelector(
    (state) => state.roomData.roomEnergyVisibility
  );
  const minMaxDict = useSelector((state) => state.roomData.rangeValues);
  const subcategory = useSelector((state) => state.roomData.currentValue);
  const haveRange = useSelector((state) => state.roomData.haveRange);

  var dataMin =
    minMaxDict?.[subcategory]?.min < 0 ? 0 : minMaxDict?.[subcategory]?.min;
  var dataMax = minMaxDict?.[subcategory]?.max;

  const selector = useSelector((state) => state.roomData.value);
  const isVisible =
    haveRange &&
    colorBarVisibility &&
    subcategory &&
    (selector?.parentName !== "") & (selector?.childName !== "");

  return { dataMax, dataMin, isVisible, subcategory };
};

export const useWindDirectionColorBar = () => {
  const {
    rangeList: [minValue, maxValue],
    value: { checkStatus: windSelector },
  } = useSelector((state) => state.windDirection);
  const isVisible = windSelector.some((e) => e);
  return { minValue, maxValue, isVisible };
};

export const useDaylightFactorColorBar = () => {
  var daylightSelector = useSelector((state) => state.daylightFactor.value);
  const subcategory = daylightSelector?.parentName;

  const isVisible =
    (daylightSelector?.parentName !== "") &
    (daylightSelector?.childName !== "");
  return { isVisible, subcategory };
};

export const usePVColorBar = () => {
  const { rangeValues, haveRange, unit, filter } = useSelector((state) => state.pvData);
  var maxVal = unit !== undefined ? rangeValues?.[unit]?.[1] : null;
  var minVal = 0;

  const selectedScenarios = Object.entries(filter)
    ?.filter(([scenario, isShown]) => isShown)
    ?.map(([scenario, isShown]) => scenario)
  const isVisible = haveRange && selectedScenarios?.length
  return { isVisible, subcategory: unit, minVal, maxVal };
};

export const useIsAnyColorBarVisible = () => {
  const { isVisible: isWindRiskVisible } = useWindRiskColorBar();
  const { isVisible: isEnergyDataVisible } = useEnergyDataColorBar();
  const { isVisible: isSolarSensorsVisible } = useSolarSensorsColorBar();
  const { isVisible: isSensorPlacementVisible } = useSensorPlacementColorBar();
  const { isVisible: isWindDirectionVisible } = useWindDirectionColorBar();
  const { isVisible: isDaylightFactorVisible } = useDaylightFactorColorBar();
  const { isVisible: isPVVisible } = usePVColorBar();
  const { isVisible: isWindTurbineVisible } = useWindTurbineColorBar();

  return (
    isWindRiskVisible ||
    isEnergyDataVisible ||
    isSolarSensorsVisible ||
    isSensorPlacementVisible ||
    isWindDirectionVisible ||
    isDaylightFactorVisible ||
    isPVVisible ||
    isWindTurbineVisible
  );
};


