import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Color, MeshBasicMaterial, SphereBufferGeometry, SphereGeometry } from "three";
import { sensorPlacementScale, normalize, generateColor } from "../utils";
// import sensorPosition from "./new_position";
import {
  updateSensorPlacementPosition,
  showFirstSensor,
  hideFirstSensor,
  updateRangeSensorPlacement,
} from "../../redux/infoWindRisk";
// import zoneOne from "../rawDataNotImplemented/zoneone";
// import zoneOneProject14 from "../rawDataNotImplemented/zoneOneProject14Top100";
// import zoneTwoProject14 from "../rawDataNotImplemented/zoneTwoProject14Top100";
// import zoneOneProject15 from "../rawDataNotImplemented/zoneOneProject15";
// import zoneOneProject52 from "../rawDataNotImplemented/zoneOneProject52";
// import zoneOneProject1273 from "../rawDataNotImplemented/zoneOneProject1273";
// import zoneTwo from "../rawDataNotImplemented/zonetwo";
import { useColorBar, useSensorPlacementColorBar } from "../CustomHooks";
import { getOldProjectId } from "../utility/hardconding";
import { SphereInstancedMesh } from "./InstancedMesh";
import axios from "axios";
import { setSensorPlacementIsLoading } from "../../redux/shadingSystem";
import { reduxLocalStorage } from "../utility/storage";

const ZoneOld = (props) => {
  var dispatch = useDispatch();
  function generateMinMax(valueList, sunSensorVal) {
    const minmaxList = [];
    for (let i = 0; i < valueList.length; i++) {
      minmaxList.push(valueList[i][sunSensorVal]);
    }

    const minVal = Math.min(...minmaxList);
    const maxVal = Math.max(...minmaxList);
    return { minVal, maxVal };
  }


  useEffect(() => {
    var sensorPlacementRangeList = generateMinMax(props.data, "overall score");
    dispatch(
      updateRangeSensorPlacement([
        sensorPlacementRangeList.minVal,
        sensorPlacementRangeList.maxVal,
      ])
    );
  }, [props])

  const { max, min } = useColorBar("Sensor Placement", "Zone", 0, 1);

  var windRiskSelector = useSelector((state) => state.shadingSystem.value);
  var geometry4 = new SphereGeometry(0.3, 20, 10);
  var hoveredGeometry = new SphereGeometry(0.4, 20, 10);
  var clickedGeometry = new SphereGeometry(0.9, 20, 10);
  let group = useRef();
  //   const f = chroma.scale(props.colorBar);
  const { sensorPlacementData: selectedSensorData, firstSensorPlacementIsShowing } = useSelector(store => store.infoWindRisk)
  const [hoveredSensor, setHoveredSensor] = useState()
  return (
    <group
      rotation-x={Math.PI * -0.5}
      name="solar sensors"
      visible={windRiskSelector.checkStatus[props.id]}
    >
      {props.data.map((sensor, i) => {
        const isHovered = hoveredSensor?.id === sensor?.id
        const isClicked = selectedSensorData?.id === sensor?.id
        return (
          <>
            <mesh
              // visible={groupZone[selectedZone]}
              visible={windRiskSelector.checkStatus[props.id]}
              geometry={(isHovered && !isClicked) ? hoveredGeometry : geometry4}
              position={[
                sensor.position[0],
                sensor.position[1],
                sensor.position[2],
              ]}
              onClick={(config) => {
                dispatch(
                  updateSensorPlacementPosition([
                    config.pageX,
                    config.pageY,
                    sensor,
                    sensor.height,
                  ])
                );
                dispatch(showFirstSensor());
                setHoveredSensor(undefined)
                config.stopPropagation();
              }}
              onPointerEnter={() => setHoveredSensor(sensor)}
              onPointerLeave={() => setHoveredSensor(undefined)}
            >
              <meshBasicMaterial
                dispose={null}
                attach="material"
                color={generateColor(
                  sensor["overall score"],
                  // sensorPlacementRangeList.minVal,
                  min,
                  max,
                  sensorPlacementScale
                )}
              // color="#f542c2"
              />
            </mesh>
            {isClicked && firstSensorPlacementIsShowing &&
              <mesh
                // visible={groupZone[selectedZone]}
                visible={windRiskSelector.checkStatus[props.id]}
                geometry={clickedGeometry}
                position={[
                  sensor.position[0],
                  sensor.position[1],
                  sensor.position[2],
                ]}
                onClick={(config) => {
                  dispatch(
                    updateSensorPlacementPosition([
                      config.pageX,
                      config.pageY,
                      sensor,
                      sensor.height,
                    ])
                  );
                  dispatch(showFirstSensor());
                  setHoveredSensor(undefined)
                  config.stopPropagation();
                }}
                onPointerEnter={() => setHoveredSensor(sensor)}
                onPointerLeave={() => setHoveredSensor(undefined)}
              >
                <meshBasicMaterial
                  dispose={null}
                  attach="material"
                  color={'yellow'}
                  transparent={true}
                  opacity={0.5}
                // color="#f542c2"
                />
              </mesh>
            }
          </>
        );
      })}
    </group>
  );
};

const Zone = (props) => {
  var dispatch = useDispatch();
  function generateMinMax(valueList, sunSensorVal) {
    const minmaxList = [];
    for (let i = 0; i < valueList.length; i++) {
      minmaxList.push(valueList[i][sunSensorVal]);
    }

    const minVal = Math.min(...minmaxList);
    const maxVal = Math.max(...minmaxList);
    return { minVal, maxVal };
  }

  var windRiskSelector = useSelector((state) => state.shadingSystem.value);

  useEffect(() => {
    var sensorPlacementRangeList = generateMinMax(props.data, "overall score");
    dispatch(
      updateRangeSensorPlacement([
        sensorPlacementRangeList.minVal,
        sensorPlacementRangeList.maxVal,
      ])
    );
  }, [props])

  const { max, min } = useColorBar("Sensor Placement", "Zone", 0, 1);
  const { sensorPlacementData: selectedSensorData, firstSensorPlacementIsShowing } = useSelector(store => store.infoWindRisk)

  const setColor = (sensor) => {
    return new Color(generateColor(
      sensor?.["overall score"],
      min,
      max,
      sensorPlacementScale
    ))
  }

  const onClick = (config) => {
    const sensor = props.data[config.instanceId];
    dispatch(
      updateSensorPlacementPosition([
        config.pageX,
        config.pageY,
        sensor,
        sensor.height,
      ])
    );
    dispatch(showFirstSensor());
    config.stopPropagation();
  }

  const getObjectPosition = (sensor) => {
    return sensor?.position
  }
  if (props?.data?.length)
    return (
      <SphereInstancedMesh
        objects={props.data}
        setColor={setColor}
        groupProps={{
          onClick,
          visible: windRiskSelector.checkStatus[props.id]
        }}
        Material={new MeshBasicMaterial()}
        getObjectPosition={getObjectPosition}
        radius={0.4}
        highlightProps={{
          highlightedObject: [selectedSensorData],
          showHighlight: firstSensorPlacementIsShowing,
          radius: 0.9,
          getObjectPosition
        }}
      />
    )

};

const SensorPlacement = () => {
  const newProjectId = reduxLocalStorage.getItem("project_id");
  // const project_id = getOldProjectId(newProjectId)

  var allProejctParameters = useSelector((state) => state.sceneVar);
  const sensorPlacementZoneConfig = allProejctParameters?.sensorPlacementZoneConfig
  const hasSensorPlacement = sensorPlacementZoneConfig?.length > 0
  const [resultArray, setResultArray] = useState();
  const token = localStorage.getItem("token");
  const [success, setSuccess] = useState(false)

  let allURLs = [];

  sensorPlacementZoneConfig?.forEach((zone) => {
    allURLs.push(
      `${process.env.REACT_APP_API_URL_2}api/sensor_placement?project_id=${newProjectId}&zone=${zone}`
    );
  });

  const dispatch = useDispatch()
  var windRiskSelector = useSelector((state) => state.shadingSystem.value);
  const { isVisible: isSensorPlacementSelected } = useSensorPlacementColorBar()

  useEffect(() => {
    var responses = {};
    if (!success && hasSensorPlacement && isSensorPlacementSelected) {
      dispatch(setSensorPlacementIsLoading(true));
      axios.all(allURLs.map((endpoint) => axios.get(endpoint, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      }))).then((data) => {
        setSuccess(true)
        dispatch(setSensorPlacementIsLoading(true));
        data.forEach((val, i) => {
          const transformedData = val.data?.map(row => ({ ...row, ...row['directional data'], id: row.ID }))
          responses[sensorPlacementZoneConfig[i]] = transformedData
        });
        setResultArray(responses);

      })
        .finally(() => {
          dispatch(setSensorPlacementIsLoading(false));
        });
    }
  }, [sensorPlacementZoneConfig, isSensorPlacementSelected]);

  return sensorPlacementZoneConfig?.map((zone, i) => {
    const id = i + 3
    if (windRiskSelector.checkStatus[id] && resultArray?.[zone])
      return <Zone id={id} data={resultArray?.[zone]} />
  })
  // if (project_id == "15" || project_id == "29")
  //   return (
  //     <>
  //       <Zone id="3" data={zoneOneProject15} />
  //       {/* <Zone id="4" data={zoneTwo} /> */}
  //     </>
  //   );

  // if (project_id == "14")
  //   return (
  //     <>
  //       <Zone id="3" data={zoneOneProject14} />
  //       <Zone id="4" data={zoneTwoProject14} />
  //     </>
  //   );
  // if (project_id == "52")
  //   return (
  //     <>
  //       <Zone id="3" data={zoneOneProject52} />
  //     </>
  //   );
  // if (newProjectId == "1273")
  //   return (
  //     <>
  //       <Zone id="3" data={zoneOneProject1273} />
  //     </>
  //   );
  // return (
  //   <>
  //     <Zone id="3" data={zoneOne} />
  //     <Zone id="4" data={zoneTwo} />
  //   </>
  // );
};

export default SensorPlacement;
